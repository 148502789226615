.cookies-popup{
	position: fixed;
	bottom: 0;
	right: 18px;
	left: 18px;
	width: 100vw-36px;
	height: fit-content;
	padding: 20px;
	background-color: #000;
	z-index: 11;
	opacity: 0.7;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	text-align: center;
	border: #000 2px solid;
	animation-name: popup;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}
.cookies-popup p:last-child{
	color: #FFF;
	font-size: 24px;
	margin-top: 1.4rem;
}

.cookies-img {
	background-image: url('./cookies.svg');
	overflow: hidden;
	width: 80px;
	height: 80px;
	filter: saturate(0.1);
	position: absolute;
	opacity: 0.7;
	top: 20%;
	left: 2%;
}


.cookies-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: 1rem;
	right: 1rem;
	font-size: 30px;
	font-weight: 500;
	margin-top: 0;
	text-shadow: #FFF 2px 2px 2px;
	color: #FFF;
}

.cookies-popup-close-button:hover{
	text-shadow: #FFF 2px 2px 2px;
	color: #600201;
}


.cookies-popup-disappear{
	position: fixed;
	bottom: 0;
	right: 18px;
	left: 18px;
	width: 100vw-36px;
	height: fit-content;
	padding: 20px;
	background-color: #000;
	z-index: 11;
	opacity: 0;
	text-align: center;
	border: #000 2px solid;
	animation-name: hide;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}



.call-popup{
	position: fixed;
	top: 77%;
	left: 0;
	width: 20vw;
	height: 20vw;
	margin: 0 2vw;
	background-color: #00F100;
	z-index: 11;
	opacity: 0;
	display: none;
	text-align: left;
	border: #00F900 2px solid;
	border-radius: 100%;
	box-shadow: #000 1px 3px 6px;

}
.call-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: -50px;
	right: -50px;
	font-size: 30px;
	font-weight: 500;
	text-shadow: #FFF 2px 2px 2px;
	visibility: hidden;
}
.call-popup a img:not(.google-ico img){
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(1) invert(1);
}
.google-ico img{
	margin-right: 10px;
	width: 40px;
	height: 40px;
	filter: brightness(0) invert(1);
}

.call-popup p{
	font-size: 11px;
	color: #FFF;
}
.call-popup a{
	color: #FFF;
	font-size: 11px;
	text-decoration: none;
}
.call-popup a:hover{
	text-decoration: none;
	transform: scale(2.0);
	color: #FFF;
}

.call-popup a:active{
	text-decoration: none;
	transform: scale(0.2);
	color: #FFF;
}

.ring {
	  /* Start the shake animation and make the animation last for 0.5 seconds */
	  animation: shake 40s;
	  animation-delay: 10s;
  	  /* When the animation is finished, start again */
	  animation-iteration-count: infinite;
	}
	
@keyframes shake {
	0% {  transform: translate(1px, 1px) rotate(0deg); }
	0.1% { transform: translate(-1px, -2px) rotate(-1deg); }
	0.2% { transform: translate(-3px, 0px) rotate(1deg); }
	0.3% { transform: translate(3px, 2px) rotate(0deg); }
	0.4% { transform: translate(1px, -1px) rotate(1deg); }
	0.5% { transform: translate(-1px, 2px) rotate(-1deg); }
	0.6% { transform: translate(-3px, 1px) rotate(0deg); }
	0.7% { transform: translate(3px, 1px) rotate(-1deg); }
	0.8% { transform: translate(-1px, -1px) rotate(1deg); }
	0.9% { transform: translate(1px, 2px) rotate(0deg); }
	1% { transform: translate(1px, 1px) rotate(0deg); }
	1.1% { transform: translate(-1px, -2px) rotate(-1deg); }
	1.2% { transform: translate(-3px, 0px) rotate(1deg); }
	1.3% { transform: translate(3px, 2px) rotate(0deg); }
	1.4% { transform: translate(1px, -1px) rotate(1deg); }
	1.5% { transform: translate(-1px, 2px) rotate(-1deg); }
	1.6% { transform: translate(-3px, 1px) rotate(0deg); }
	1.7% { transform: translate(3px, 1px) rotate(-1deg); }
	1.8% { transform: translate(-1px, -1px) rotate(1deg); }
	1.9% { transform: translate(1px, 2px) rotate(0deg); }
	2% { transform: translate(1px, -2px) rotate(-1deg); }
	2.1% { transform: translate(-1px, -2px) rotate(-1deg); }
	2.2% { transform: translate(-3px, 0px) rotate(1deg); }
	2.3% { transform: translate(3px, 2px) rotate(0deg); }
	2.4% { transform: translate(1px, -1px) rotate(1deg); }
	2.5% { transform: translate(-1px, 2px) rotate(-1deg); }
	2.6% { transform: translate(-3px, 1px) rotate(0deg); }
	2.7% { transform: translate(3px, 1px) rotate(-1deg); }
	2.8% { transform: translate(-1px, -1px) rotate(1deg); }
	2.9% { transform: translate(1px, 2px) rotate(0deg); }
	3% { transform: translate(1px, 1px) rotate(0deg); }
	3.2% { transform: translate(-3px, 0px) rotate(1deg); }
	3.3% { transform: translate(3px, 2px) rotate(0deg); }
	3.4% { transform: translate(1px, -1px) rotate(1deg); }
	3.5% { transform: translate(-1px, 2px) rotate(-1deg); }
	3.6% { transform: translate(-3px, 1px) rotate(0deg); }
	3.7% { transform: translate(3px, 1px) rotate(-1deg); }
	3.8% { transform: translate(-1px, -1px) rotate(1deg); }
	3.9% { transform: translate(1px, 2px) rotate(0deg); }
}

@keyframes popup {
  from {
  	opacity: 0;
  	display: none;
  }
  to {
  	opacity: 0.6;
  	display: initial;
  }
}

@keyframes hide-animation {
  from {
  	opacity: 0.7;
  }
  to {
  	display: none;
  	opacity: 0;
  }
}


@media only screen and (max-width: 600px) {
	.call-popup{
		animation-name: popup;
		animation-duration: 3s;
		animation-delay: 3s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
		display: block;
	}

}

