@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}



#wrapper-comments {
  position: relative;
  height: 40vh;
  width: 100%;
  background-color: rgba(255,255,255,1);
  margin: 5vh 0;
  background-image: url('google_reviews.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-size: 10%;
  border-radius: 15px;

}
#comments p {
  font-size: 3.6rem;
}

#comments-rate{
  width: 50%;
  height: 50%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%; 
}


#comments-ico {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 41%;
    margin: 0 30%;
    width: 40%;
    min-height: 50vh;
    height: fit-content;
    position: relative;
    top: 5rem;
    opacity: 0;
    animation: fadeIn 0.6s 0.2s forwards;
  }

  #comments-picture-before-after-frame {

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    
  }
  #comments-picture-frame {

    width: 100%;
    height: 100%;
  }

  #comments-picture {

    background-size: contain;
    background-position-x: 50%;
    background-repeat: no-repeat;
    margin: 5% auto;
    width: 96%;
    min-height: 30vh;
    opacity: 0;
    animation: fadeIn 0.6s 0.2s forwards;
  }

  #comments-picture-centered {
    background-position-x: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 5% auto;
    width: 96%;
    min-height: 45vh;
    opacity: 0;
    animation: fadeIn 0.6s 0.2s forwards;
  }
  
  #comments-picture-caption {
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    width: 96%;
    min-height: 5vh;
    position: relative;
    opacity: 0;
    animation: fadeIn 0.6s 0.2s forwards;
  }

  @keyframes knowhow-roll {
    0% {
      visibility: visible;
      z-index: 2;
      opacity: 0;

    }

    5% {
      visibility: visible;
      z-index: 4;
      opacity: 1;

    }
    9% {
      visibility: visible;
      z-index: 3;
      opacity: 1;

    }  

    25% {
      visibility: hidden;
      z-index: -1;
      opacity: 0;

    }
    26% {
      visibility: hidden;
      z-index: -1;
      opacity: 0;

    }
  }
  #comments-top-beam:first-of-type{
    animation: knowhow-roll 24s infinite forwards;
  }

  #comments-top-beam:nth-of-type(2){
    animation: knowhow-roll 24s 6s infinite forwards;
  }

  #comments-top-beam:nth-of-type(3){
    animation: knowhow-roll 24s 12s infinite forwards;
  }

  #comments-top-beam:nth-of-type(4){
    animation: knowhow-roll 24s 18s infinite forwards;
  }


  #comments-tilbud {
    visibility: visible;
    z-index: -1;

    font-size: 3em;
    margin: 5vh 0vw;
    list-style-type: none;
    cursor: default;
    color: #000;
    font-family: 'Dosis';
    font-variant: small-caps;
    opacity: 0;
    animation: fadeIn 0.3s 0.8s forwards;
    background-color: rgba(255,255,255,1);
    width: 100%;
    height: fit-content;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 40px solid rgba(255, 255, 255, 0.1);
  }

  #comments-tilbud div:hover {
    color: #000;
    
  }

  #comments-tilbud div div {
    background-size:contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 30%;  
    margin: 0 0%;
    min-width: 70%;
    min-height: 30vh;
  }

  #comments-tilbud p {
    padding: 0.5vh 0vw;
    font-size: 3vw;
    font-weight: 600;
  }

  #comments-top-beam {
    visibility: visible;
    z-index: -1;
    position: absolute;
    border-radius: 15px;
    top: 0;
    font-size: 3em;
    margin: 1vh 0vw;
    list-style-type: none;
    cursor: default;
    color: #000;
    opacity: 0;
    animation: fadeIn 0.3s 0.8s forwards;
    width: 100%;
    height: fit-content;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 40px solid rgba(255, 255, 255, 0.1);
  }

  #comments-top-beam div:hover {
    color: #000;
    
  }

  #comments-top-beam div div {
    background-size:contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 30%;  
    margin: 0 0%;
    min-width: 70%;
    min-height: 30vh;
  }

  #comments-top-beam p {
    padding: 0.5vh 0vw;
    font-size: 3vw;
    font-weight: 600;
    text-align: left;
  }

  #comments-header {
    padding: 2rem 0;
    width: 100%;
    height: fit-content;
    color: #000;
    font-size: 4.4rem;
    cursor: default;
    font-family: 'Dosis';
    font-variant: small-caps;
    text-align: left;
  }

  #comments-description {
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 40px solid rgba(255, 255, 255, 0.1);
    opacity: 0;
    font-size: 150%;
    animation: fadeIn 0.4s 0.4s forwards;
  }

  #comments-description p:first-of-type{
    margin: 5vh 0;
    font-size: 150%;
    font-weight: 800;
  }
 
  #comments-description p:nth-of-type(2){
     font-family: 'Dosis';
     font-size: 140%;
     margin: 3vh 0;
  } 
  #comments-description p:nth-of-type(3), #comments-description p:nth-of-type(4), #comments-description p:nth-of-type(5), #comments-description p:nth-of-type(6), #comments-description p:nth-of-type(7),#comments-description p:nth-of-type(8), #comments-description p:nth-of-type(9){
    font-size: 130%;
  }
  

  #comments-description h1 {
    font-family: 'Dosis';
    font-variant: small-caps;
    font-size: 3vw;
    margin: 2vh 0;
  }

  #comments-description p{
    font-family: 'Imprima';
    
    justify-content: center;
    font-size: 70%;
    width: 100%;
    height: 100%;
  }
  
  #comments {
    margin: 5vh 0;
  }
  
#comments-gallery {
    margin: 0 10%;
}

#comments-beam-header {
  background-color: rgba(255,255,255,1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 40px solid rgba(255, 255, 255, 0.1);
  width: 98%;
  margin: auto auto;
}


#comments-link:hover {
  border: #000 3px solid;
  border-radius: 15px;
}

@media only screen and (max-width: 600px) {
  #comments-header {
    font-size: 2.2rem;
  }
  #wrapper-comments {
    position: relative;
    height: 80vh;
  }
  #comments-sliping p {
    padding: 1.5vh 0vw;
    font-size: 4vh;
    font-weight: 600;
  }
  #comments-description h1 {
    display: none;
  }
  #comments-gallery {
    margin: 0 2%;
}
#comments-picture {
  min-height: 15vh;
}

#comments-picture-centered {
  min-height: 20vh;
 }

}
