.App {
  text-align: center;
  background-color: rgba(255,255,255,0.7);
}

header h2, footer h2 {
  -webkit-text-stroke: 1px black;
  font-variant: small-caps;
}
header p {
  -webkit-text-stroke: 0.1px black;
}

header {
  border-top: 1px #000 solid;  
  border-bottom: 1px #000 solid;
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.021), 
  0px 0px -14px rgba(255, 255, 255, 0);
}
nav {
  position: sticky;
  background: hsla(0, 0%, 100%, .9);
  top: 0;
  left: 0;
  right: 0;
  font-variant: small-caps;
  z-index: 11;
}

main {
}

nav img {
  height: 30px;
  margin: 0 10px 0 10px;
}

.App-header {
  background-color: #282c34;
  opacity: 0.7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.content {
  position: relative;
  padding: 2vw;
  min-width: 80vw;
  min-height: 40vh;
  background: hsla(0, 0%, 100%, .9);
  border-radius: 15px;
  display: grid;
  grid-template-columns:30% 70%;
  z-index: 9;
}
.content::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 100%, .7);
  z-index: -1;
  border-radius: 15px;
}

.content-background {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background-image: url('./background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 40%;
  border-radius: 15px;
  z-index: -1;
  opacity: 0.8;
}

.contact-us {
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: relative;
  margin: 10vh 0 15vh 0;
  padding: 2vw;
  min-width: 70vw;
  min-height: 40vh;
  border-radius: 15px;
  background: hsla(0, 0%, 100%, .1);
  display: grid;
  grid-template-columns: 50% 25% 25%;
  
}

.order-befaring {
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: relative;
  margin: 10vh 0 15vh 0;
  padding: 2vw;
  min-width: 70vw;
  min-height: 40vh;
  border-radius: 15px;
  background: hsla(0, 0%, 100%, .7);
  display: grid;
  grid-template-columns: 50% 50%;
  
}


.contact-us-description {
  background: hsla(0, 0%, 100%, .6);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 5% 0;
  width: 100%;
  height: 100%;
  display: inline;
  font-family: 'Oxygen';
  font-variant: small-caps;
}
.contact-us-description span {
  font-size: 4rem;
  font-variant: normal;
  font-family: 'Satisfy';
  color: #000;
}


.contact-us::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 100%, .8);
  border-radius: 15px;
  z-index: -1;
}



.gallery {
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: relative;
  margin: 10vh 0 15vh 0;
  padding: 2vw;
  min-width: 70vw;
  min-height: 40vh;
  border-radius: 15px;
  background: hsla(0, 0%, 100%, .7);
}
.gallery::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 100%, .8);
  border-radius: 15px;
  z-index: -1;
}

.preface {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 30px;
  padding: 10px;
  position: relative;
  margin: 0;
  padding: 2vw;
  min-width: 70vw;
  min-height: 40vh;
  background: hsla(0, 0%, 100%, .6);
}
.preface::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 100%, .5);
  border-radius: 3%;
  z-index: -1;
  
}


.preface div {
  vertical-align: middle;
}

.content h1, .preface h1, .gallery h1 {
  font-variant: small-caps;
  margin: 0 0 5vh 0;
  text-align: center;
}

.picture {
  overflow: hidden;
  box-shadow:  1px 1px 4px hsla(0, 0%, 100%, .9);
  border-radius: 3%;
  margin: 25% auto;
  padding-top: 0%;
  transform: rotate(45deg);
  z-index: 10;
  border: 3px #600201 solid;
  width: 300px;
  height: 300px;
  animation: rotateIn 1.5s forwards ease-in;
  position: relative;
}

.picture-img {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 180%;
  transform: rotate(-45deg);
  width: 500px;
  height: 500px;
  position: absolute;
  top: -140px;
  left: -90px;
  animation: rotateInImage 1.5s forwards ease-in;
  z-index: 11;
}

.picture-logo {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  opacity: 1;
  animation-name: pageOut;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

}

@-webkit-keyframes rotateIn{
  1% {
    transform: rotate(45deg);
    border: 1px #FFF solid;
    
  }
  50% {
    border: 1px #FFF solid;
    
  }
  100% { 
    transform: rotate(0deg);
    border: 3px #600201 solid;
  }
}

@keyframes rotateIn{
  1% {
    transform: rotate(45deg);
    border: 1px #FFF solid;
    
  }
  50% {
    border: 1px #FFF solid;
    
  }
  100% { 
    transform: rotate(0deg);
    border: 3px #600201 solid;
  }
}

@-webkit-keyframes rotateInMobileImage{
  1% {
    transform: rotate(-45deg);
    background-size: 180%;
    top: -140px;
    left: -90px;
    width: 500px;
    height: 500px;
  }
  50% {
    background-size: 160%;
    top: -40px;
    left: -40px;
    
  }
  100% { 
    transform: rotate(0deg);
    background-size: 105%;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
  }
}

@keyframes  rotateInMobileImage{
  1% {
    transform: rotate(-45deg);
    background-size: 180%;
    top: -140px;
    left: -90px;
    width: 500px;
    height: 500px;
  }
  50% {
    background-size: 160%;
    top: -60px;
    left: -60px;
    
  }
  100% { 
    transform: rotate(0deg);
    background-size: 105%;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
  }
}

@-webkit-keyframes rotateInImage{
  1% {
    transform: rotate(-45deg);
    background-size: 180%;
    top: -140px;
    left: -90px;
    width: 500px;
    height: 500px;
  }
  50% {
    background-size: 160%;
    top: -40px;
    left: -40px;
    
  }
  100% { 
    transform: rotate(0deg);
    background-size: 105%;
    top: 0px;
    left: 0px;
    width: 300px;
    height: 300px;
  }
}

@keyframes  rotateInImage{
  1% {
    transform: rotate(-45deg);
    background-size: 180%;
    top: -140px;
    left: -90px;
    width: 500px;
    height: 500px;
  }
  50% {
    background-size: 160%;
    top: -60px;
    left: -60px;
    
  }
  100% { 
    transform: rotate(0deg);
    background-size: 105%;
    top: 0px;
    left: 0px;
    width: 300px;
    height: 300px;
  }
}
.description {
  background: hsla(0, 0%, 100%, .6);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: justify;
  padding: 10% 10%;
  display: inline;
  border-left: #600201 3px solid;
}

.description p {
  margin: 3% 0;
}

.description h1
{
  font-family: 'DM Serif Text';
  font-size: 400%;
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.description p:nth-child(2)
{
  font-family: 'Satisfy';
  font-size: 220%;
  opacity: 0;
  text-align: center;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.description p:nth-child(4)
{
  font-family: 'Oxygen';
  font-size: 180%;
  opacity: 0;
  text-align: justify;
  animation-name: pageIn;
  animation-duration: 1.1s;
  animation-delay: 0.7s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}


.description p:nth-child(3)
{
  font-family: 'Nunito';
  font-size: 180%;
  opacity: 0;
  font-weight: 800;
  text-align: justify;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.description p:nth-child(5)
{
  font-family: 'Oxygen';
  font-size: 170%;
  opacity: 0;
  text-align: justify;
  animation-name: pageIn;
  animation-duration: 1.1s;
  animation-delay: 0.8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.description p:nth-child(6)
{
  font-family: 'Oxygen';
  font-size: 170%;
  opacity: 0;
  text-align: justify;
  animation-name: pageIn;
  animation-duration: 1.1s;
  animation-delay: 0.8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.description p:nth-child(7)
{
  font-family: 'Oxygen';
  font-size: 170%;
  opacity: 0;
  text-align: justify;
  animation-name: pageIn;
  animation-duration: 1.1s;
  animation-delay: 0.8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.description p:nth-child(8)
{
  font-family: 'Oxygen';
  font-size: 170%;
  opacity: 0;
  text-align: justify;
  animation-name: pageIn;
  animation-duration: 1.1s;
  animation-delay: 0.8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.footer {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 10px;
  padding: 10px;
  position: relative;
  left:0;
  right: 0; 
  background: hsla(0, 0%, 90%, .7);
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.021), 
  0px 0px -14px rgba(255, 255, 255, 0);
  min-height: 120px;
  width: 100%;
}


.footer-contact {
  margin: 0 15px;
}

.social-ico a:active{
	color: #FFF;
	transform: scale(0.8);
	text-decoration: none;
}

.social-ico p{
  display: inline;
  margin: 0 15px;
}


.social-ico img {
	height: 40px;
	width: 40px;
	margin-bottom: 10px;
}


.footer-logo img{
	height: 70px;
	width: 70px;
  margin: 0 10px;
}

.footer-logo p:not(.footer-address){  
  display: inline;
  font-variant: small-caps;
}

div .thumbnail
{
  min-height: 140px;
	align-content: right;
  margin: 10vh 0;
}

main {
  width: 90%;
  margin: 5% 5%;
  padding-bottom: 5%;
}

.miniature {
  border-radius: 15px;
  width: 17vw;
  height: 17vw;
  margin: 15px;
  display: inline-grid; 
  transform: rotate(45deg);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.miniature-logo {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
  width: 17vw;
  height: 17vw;
  position: absolute;
  display: inline;
  top: 0;
  left: 0;
  z-index: 12;
  opacity: 1;
  animation-name: pageOut;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

}

.miniature:nth-child(1) {
  animation: rotateIn 1s 0s forwards;
}
.miniature:nth-child(2) {
  animation: rotateIn 1s 0.1s forwards;
}
.miniature:nth-child(3) {
  animation: rotateIn 1s 0.2s forwards;
}
.miniature:nth-child(4) {
  animation: rotateIn 1s 0.3s forwards;
}
.miniature:nth-child(5) {
  animation: rotateIn 1s 0.4s forwards;
}
.miniature:nth-child(6) {
  animation: rotateIn 1s 0.5s forwards;
}
.miniature:nth-child(7) {
  animation: rotateIn 1s 0.6s forwards;
}
.miniature:nth-child(8) {
  animation: rotateIn 1s 0.7s forwards;
}
.miniature:nth-child(9) {
  animation: rotateIn 1s 0.8s forwards;
}
.miniature:nth-child(10) {
  animation: rotateIn 1s 0.9s forwards;
}
.miniature:nth-child(11) {
  animation: rotateIn 1s 1s forwards;
}
.miniature:nth-child(12) {
  animation: rotateIn 1s 1.1s forwards;
}
.miniature:nth-child(13) {
  animation: rotateIn 1s 1.2s forwards;
}
.miniature:nth-child(14) {
  animation: rotateIn 1s 1.3s forwards;
}
.miniature:nth-child(15) {
  animation: rotateIn 1s 1.4s forwards;
}
.miniature:nth-child(16) {
  animation: rotateIn 1s 1.5s forwards;
}
.miniature:hover {
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.9);
}

.miniature:active {
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  opacity: 1;
  transition: 1s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
}

#tilbud-top-beam {
  font-size: 3em;
  list-style-type: none;
  cursor: default;
  color: #000;
  font-family: 'Oxygen';
  font-variant: small-caps;
  opacity: 0;
  animation: pageIn 0.3s 0.8s forwards;
  background-color: rgba(255,255,255,0.9);
  width: 100%;
  height: fit-content;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 40px solid rgba(255, 255, 255, 0.1);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: #600201 3px solid;
  display: grid;
  grid-template-columns: 70% 30%;
}

input[type='range'] {
  accent-color: #600201;
}

#tilbud-top-beam h4 {
  position: relative;
}

.sub {
	position:relative;
	font-size: 0.5em; /* trip haslayout, make positioning behave IE */
	line-height:1em; /* should equal surrounding line-height */
	vertical-align:middle;
	top:-1em;
}

#tilbud-top-beam p {
  padding: 0.5vh 0vw;
  font-size: 3vw;
  font-weight: 600;
}

#tilbud-top-beam a {
  font-variant: normal;
  font-family: 'Satisfy';
  color: #000;
}

#tilbud-top-beam a:hover {
  font-weight: bolder;
  text-decoration: none;
}


.contact-data {
  color: #000;
}

#contact-data:hover {
  transform: scale(0.85);
  text-decoration: none;
}

.contact-data:hover h1 {
  text-decoration: none;
}


#contact-data {
  background: rgba(14, 171, 20, 0.76);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: #FFF;
  padding: 10vh 1vw;
  margin: 0vh 3vw;
  font-size: larger;
  font-variant: small-caps;
  box-shadow: #000 0px 5px 10px;
  background-repeat: no-repeat;
  background-size: 90%;
  border: 0px solid black;
  position: relative;
  left: 2%;
  width: 17vw;
  height: 17vw;
  border-radius:15px;
  transform: scale(0.8);
  z-index: 3;
  display: inline-block;
}
#contact-data h1:nth-child(1){
  -webkit-text-stroke: 1px #FFF;
  color: #000;
  font-size: 3em;
  margin-top: 2vh;
}

#webdesign {
  background-color: #FFF;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 10px;
  padding: 10px;
  position: relative;
  left:0;
  right: 0; 
  background: #FFF;
  min-height: 20px;
  width: 100%;
}


@-webkit-keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes pageOut{
  from { 
    opacity: 1; 
    display: inline;
    
  }
  to { 
    opacity: 0; 
    display: none;
  }
}

@keyframes pageOut{
  from { 
    opacity:1; 
    display: inline;
    
  }
  to { 
    opacity: 0; 
    display: none;
  }
}


@media only screen and (max-width: 600px) {


  #tilbud-top-beam {
    grid-template-columns: 100%;
  }
  .contact-us, .order-befaring {
    grid-template-columns: 100%;
    
  }

  .description {
    margin: 0;
    text-align: justify;
    font-size: 100%;
    border-left: none;
    border-top: 1px #600201 solid;
  }

  header {
    min-height: 15px;
  }


  .content {
    margin: 1% ;
    min-width: 80vw;
    display: grid;
    grid-template-columns:100%;
  }

  .content-background {

    background-image: url('./bg_mobile.jpg');

  }

  .footer {
    
    display: inline-block;
  }

  #contact-data
  {
    width: 60vw;
    height: 60vw;
  }
  #webdesign {
    background-color: #FFF;
    display: grid;
    grid-template-columns: 100%;
  }

  
    
  .description h1
  {
    font-size: 200%;
   }
  
  .description p:nth-child(2)
  {
    font-size: 140%;

  }
  
  .description p:nth-child(4)
  {
    font-size: 130%;
    }
  
  
  .description p:nth-child(3)
  {
  
    font-size: 120%;
 
  }
  
  .description p:nth-child(5)
  {
    font-size: 110%;
  }

  .picture {
    width: 200px;
    height: 200px;
    animation: rotateIn 1.5s forwards ease-in;
  }
  
  .picture-img {
    width: 500px;
    height: 500px;
    animation: rotateInMobileImage 1.5s forwards ease-in;
  }
  
  .picture-logo {
    width: 200px;
    height: 200px;
  }
}






